import React, {Component} from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import ontbijt from "assets/img/ontbijt_arrangement.JPG";
import diner_arrangement from "assets/img/diner_arrangement.JPG";
import sleep_barrel from "assets/img/overnachting_arrangement.JPG";
import tapas from "assets/img/luxe_arrangement.JPG";
import Table from "../components/Table/Table";
import SingleArrangement from "./SingleArrangement";
import ComponentHeader from "./ComponentHeader";
import arrangements from "data/arrangementen.json"


Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

class Arrangements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrangements: arrangements
    }
  }

  render() {
    const {arrangements} = this.state

    let standard_arrangement = arrangements ? arrangements.standard_arrangement : {}
    let luxe_arrangement = arrangements ? arrangements.luxe_arrangement : {}
    let breakfast_arrangement = arrangements ? arrangements.breakfast_arrangement : {}
    let lunch_diner_arrangement = arrangements ? arrangements.lunch_diner_arrangement : {}
    let night_arrangement = arrangements ? arrangements.night_arrangement : {}


    return (
      <div style={{textAlign: "center"}}>
        <ComponentHeader title={"Arrangementen"} />
        <h4>Kies uit één van onze arrangementen. Badlinnen en slippers zijn telkens inbegrepen in de prijs</h4>
        <GridContainer justify={"center"}>
          <GridItem xs={12} md={4}>
            <h3>Relax - Standaard Arrangement</h3>
            <Table
              tableData={standard_arrangement.standard_prices ? standard_arrangement.standard_prices.map((row, row_idx)=>{
                if (row_idx === 0) return row
                if (row_idx === standard_arrangement.standard_prices.length - 1) return row
                return row.map((item, idx)=> {
                  if (idx === 0) return item
                  return "€ " + item
                })
              }) : []}
            />
          </GridItem>
        </GridContainer>
        <br/>
        <SingleArrangement
          image={ontbijt}
          arrangement={breakfast_arrangement}
        />
        <br/>
        <SingleArrangement
          image={tapas}
          arrangement={luxe_arrangement}
        />
        <br/>
        <SingleArrangement
          image={diner_arrangement}
          arrangement={lunch_diner_arrangement}
        />
        <br/>
        <SingleArrangement
          image={sleep_barrel}
          arrangement={night_arrangement}
        />
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default Arrangements;