import React from "react";
import {Route} from "react-router-dom";
import Arrangements from "../Arrangements";
import Header from "../../components/Header/Header";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../../assets/jss/material-kit-react/views/components";
import image from "../../assets/img/background-wooden.png";
import image2 from "../../assets/img/background_header.jpg";
import image2_mobile from "../../assets/img/background_header_mobile.jpg";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Parallax from "../../components/Parallax/Parallax";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import logo from "../../assets/img/logo/PNG/Relax_Logo_Kleur_Marmer.png";
import vakantie from "../../assets/img/vakantie2024.jpg";
import classNames from "classnames";
import Footer from "../../components/Footer/Footer";
import Menu from "../Menu";
import Facilities from "../Facilities";
import Contact from "../Contact";

class ReactRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    const {classes} = this.props

    return (
      <div id="navbar" className={classes.navbar}>
        <div
          className={classes.navigation}
          style={{ backgroundImage: "url(" + image + ")" }}
        >
          <Header
            brand="Relax Privé Sauna"
            rightLinks={<HeaderLinks />}
            fixed
            color="dark"
          />

          <Parallax image={window.screen.width > 739 ? image2 : image2_mobile}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={6}>
                  <div className={classes.brand}>
                    <img src={logo} alt="Relax Privé Sauna" style={{"height":"250px"}} />
                  </div>
                </GridItem>
                <GridItem xs={6}>
                  <div className={classes.brand}>
                    <img src={vakantie} alt="Relax Privé Sauna" style={{"height":"250px"}} />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>

          <div className={classNames(classes.main, classes.mainRaised)}>
            <Route  exact path="/" component={Facilities} />
            <Route  path="/arrangements" component={Arrangements} />
            <Route  path="/menu" component={Menu} />
            <Route  path="/contact" component={Contact} />
          </div>

          <Footer />

        </div>
      </div>

    );
  }
}

export default withStyles(styles)(ReactRouter);